import Icon from '@/components/icon'
import Link from '@/components/link'
import { useCommonStore } from '@/store/common'
import { getCustomerRoutePath } from '@/helper/route/customer'
import { useGetCustomerJumpUrl } from '@/hooks/features/customer'
import styles from './index.module.css'

function FloatingIconButton() {
  const customerUrl = useGetCustomerJumpUrl()

  const { localeInfo } = useCommonStore()
  const { customerService: { isNewlyOpened = false } = {} } = localeInfo || {}

  return (
    <Link href={isNewlyOpened ? customerUrl || '' : getCustomerRoutePath()} target={isNewlyOpened}>
      <Icon className={styles.scoped} name="message_v2" />
    </Link>
  )
}

export default FloatingIconButton

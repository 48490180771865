import { t } from '@lingui/macro'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import RecommendGames from '@/features/recreation/theme/components/recommend-games'
import { GameGroupSupplierTypeProps, RaHallGameTypeProps } from '@/typings/api/game'
import { link } from '@/helper/link'
import { getGameSuppliersRoutePath } from '@/helper/route/game'
import { shouldHiddenMoreByTotal } from '@/helper/game/home'
import { RaGameTypeEnum } from '@/constants/game'
import { GetUseGameSuppliersMethods } from '@/constants/common'
import HomeLobbyGame from './home-lobby-game'

type HomeLobbyProps = {
  data: RaHallGameTypeProps[]
  supplierData: GameGroupSupplierTypeProps[]
}

enum HomeLobbyEnum {
  one = 1,
  three = 3,
  maxNum = 21,
}

export default function HomeLobby({ data, supplierData }: HomeLobbyProps) {
  const { theme, localeInfo } = useCommonStore()
  return (
    <div>
      {data?.map((item, index) => {
        const minShow = data?.length < HomeLobbyEnum.three && index + HomeLobbyEnum.one === data?.length
        const maxShow = data?.length >= HomeLobbyEnum.three && index + HomeLobbyEnum.one === HomeLobbyEnum.three
        if (minShow || maxShow) {
          return (
            <div key={item?.id} className="mt-8">
              {GetUseGameSuppliersMethods(localeInfo?.home?.hall?.provider) && (
                <RecommendGames
                  title={t`features_layout_content_compnent_layout_menu_index_00f9dlrqie`}
                  titleIcon="icon_games_bjl_sidebar_v2"
                  className="game-wrap"
                  gamesData={supplierData}
                  type={RaGameTypeEnum.provider}
                  onMore={() => link(getGameSuppliersRoutePath())}
                  hiddenShowMore
                  showMoreCard={!shouldHiddenMoreByTotal(supplierData?.length ?? 0)}
                />
              )}

              <HomeLobbyGame
                title={item?.name}
                titleIcon={theme === ThemeEnum.dark ? item?.icon : item?.whiteIcon}
                id={item?.id}
                code={item?.code}
              />
            </div>
          )
        } else {
          return (
            <HomeLobbyGame
              key={item?.id}
              title={item?.name}
              titleIcon={theme === ThemeEnum.dark ? item?.icon : item?.whiteIcon}
              id={item?.id}
              code={item?.code}
            />
          )
        }
      })}
    </div>
  )
}

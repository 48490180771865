import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { IGameTabsProps } from '@/features/recreation/component/game-tabs'
import GameTabs2z from '@/features/recreation/theme/2z/game-tabs'
import GameTabsOk from '@/features/recreation/theme/ok/game-tabs'

export default function GameTabs(props: IGameTabsProps) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameTabs2z {...props} />
    case InitThemeColor.ok:
      return <GameTabsOk {...props} />
    default:
      return <GameTabs2z {...props} />
  }
}

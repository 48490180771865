/**
 * 游戏切换 tab
 */
import Icon from '@/components/icon'
import classNames from 'classnames'
import React, { ReactNode, Ref, useRef } from 'react'
import { Tabs, Image } from '@nbit/arco'
import { RaHallGameTypeProps } from '@/typings/api/game'
import { useInViewport } from 'ahooks'
import { t } from '@lingui/macro'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import styles from './index.module.css'

export interface IGameTabsProps {
  className?: string
  /** 是否固定展示第一项 tab（如：大厅） */
  isFixedFirstTab?: boolean
  /** 固定 tab 数据 */
  fixedTabData?: {
    name: string
    id: string
    content?: ReactNode
    icon: string
  }
  /** 是否需要展示对应 tab 下的 DOM */
  isShowTabContent?: boolean
  /** tabs */
  tabs?: Array<RaHallGameTypeProps>
  /** 当前 tab */
  currentTab: string | number
  /** 修改 tab */
  onChange: (v: string) => void
  // 转发的 ref
  forwardedRef?: Ref<HTMLDivElement>
}

enum GameTabsProps {
  none,
}

export default function CommonGameTabs(props: IGameTabsProps) {
  const { theme } = useCommonStore()
  const {
    tabs,
    className,
    isFixedFirstTab = true,
    fixedTabData = {
      icon: 'icon_dating_v2',
      name: t`features_home_component_game_tabs_index_jeq3nacrgk`,
      id: GameTabsProps.none,
      content: '',
    },
    currentTab,
    forwardedRef,
    onChange,
  } = props || {}
  const firstRef = useRef(null)
  const lastRef = useRef(null)
  const [firstInViewport] = useInViewport(firstRef)
  const [lastInViewport] = useInViewport(lastRef, {
    threshold: 1,
  })

  const onRenderTab = (tab, index: number, isIcon?: boolean) => {
    return (
      <div
        className={classNames('tab-cell', {
          active: currentTab === tab?.id,
        })}
        onClick={() => onChange(tab?.id)}
        ref={index === 0 ? firstRef : index + 1 === tabs?.length ? lastRef : null}
      >
        {isIcon ? (
          <Icon name={tab.icon} className="tab-icon" />
        ) : (
          <Image src={theme === ThemeEnum.dark ? tab?.icon : tab?.whiteIcon} preview={false} className="tab-image" />
        )}

        <span className="tab-name">{tab.name}</span>
      </div>
    )
  }

  return (
    <div className={classNames(styles['game-tabs-root'], className)} ref={forwardedRef}>
      <div className={'game-tabs'}>
        {isFixedFirstTab && onRenderTab(fixedTabData, -1, true)}
        {!!tabs?.length && (
          <Tabs
            activeTab={currentTab as string}
            tabPosition="top"
            type="rounded"
            direction="horizontal"
            className="tabs-wrap"
          >
            {tabs?.map((x, i: number) => (
              <Tabs.TabPane destroyOnHide key={x.id} title={onRenderTab(x, i)} />
            ))}
          </Tabs>
        )}
      </div>
    </div>
  )
}

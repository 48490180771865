import { useState } from 'react'
import { useMount, useRequest } from 'ahooks'
import { Carousel } from '@nbit/arco'
import { BannerListTypeProps } from '@/typings/api/game'
import { getGameBannerList } from '@/apis/game'
import styles from './index.module.css'

export default function HomePageBannerOk() {
  const [currentBannerData, setCurrentBannerData] = useState<BannerListTypeProps[]>([])

  /** 获取 banner */
  const getHomeBasiceList = async () => {
    const { data: bannerData, isOk: isBannerOk } = await getGameBannerList({})
    if (isBannerOk && bannerData) {
      setCurrentBannerData(bannerData || [])
    }
  }

  const { run: bannerRun } = useRequest(getHomeBasiceList, { manual: true })

  /** 点击 banner */
  const onCarouselChange = src => {
    window.location.href = src?.webTargetUrl
  }

  useMount(bannerRun)

  return (
    <div className={styles.scoped}>
      {!currentBannerData?.length ? <div className="no-data-carousel" /> : null}

      <Carousel autoPlay className="home-header-carousel" arrowClassName={styles.arrow}>
        {currentBannerData.map(src => (
          <div className="header-carousel" key={src?.id} onClick={() => onCarouselChange(src)}>
            <img src={src?.webImage} className="item-image" alt="" />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

// import { t } from '@lingui/macro'
import { useMount, useRequest } from 'ahooks'
import { GameTypeEnum } from '@/helper/game'
import { useEffect, useState, useRef } from 'react'
import { usePageContext } from '@/hooks/use-page-context'
import { Spin } from '@nbit/arco'
import classNames from 'classnames'
import { ThemeEnum } from '@nbit/chart-utils'
import { useCommonStore } from '@/store/common'
import GamesSearch from '@/features/recreation/theme/components/games-search'
import HomeLobby from '@/features/recreation/component/home-lobby'
import HomeOtherLobby from '@/features/recreation/component/home-other-lobby'
import RankingListTable from '@/features/recreation/component/ranking-list-table'
import { getGameGroupSupplier, getGameTypeList } from '@/apis/game'
import { GameGroupSupplierTypeProps, RaHallGameTypeProps } from '@/typings/api/game'
import { RequestCacheKeyEnum, RequestCacheTimeEnum } from '@/constants/common'
import PlaceFadeInMask, { ActionRef } from '@/components/place-fade-in'
import AdvertPopup from '@/features/advert/advert-popup'
import HomePageBanner from '@/features/recreation/theme/components/home-page-banner'
import GameTabs from '@/features/recreation/theme/components/game-tabs'
import styles from './index.module.css'
import FloatingIconButton from '../home/floating-icon-button'

enum HomeDefaultTypeProps {
  clear = -1,
  none,
  one,
  maxPage = 18,
}

export function Home() {
  const [tabs, setTabs] = useState<number | string>(HomeDefaultTypeProps.clear)
  const [currentMenu, setCurrentMenu] = useState<RaHallGameTypeProps>()
  const [supplierData, setSupplierData] = useState<GameGroupSupplierTypeProps[]>([])
  const [gameTabsWidth, setGameTabsWidth] = useState<number>(0)
  const fadeInRef = useRef<ActionRef>(null)

  const pageContext = usePageContext()
  const homeRef = useRef<HTMLElement>(null)
  const gameTabsRef = useRef<HTMLDivElement>(null)
  const { theme, localeInfo } = useCommonStore()

  /** 获取路由参数 */
  const getRouterId = () => {
    const { urlParsed } = pageContext
    const { search } = urlParsed
    const tabId = search?.tabId
    return { tabId }
  }

  /** 获取游戏列表 */
  const getGameTypeData = async () => {
    const params = {
      sectionCd: GameTypeEnum.hall,
    }
    const { isOk, data } = await getGameTypeList(params)
    if (isOk && data) {
      return data
    }
    return []
  }

  const { loading, data: menuList = [] } = useRequest(getGameTypeData, {
    cacheKey: RequestCacheKeyEnum.homeGameCategory,
    cacheTime: RequestCacheTimeEnum.homeGameList,
  })

  /** 处理首页展示 */
  const handleHomeShow = v => {
    if (v === HomeDefaultTypeProps.clear) return
    if (v === HomeDefaultTypeProps.none) {
      return <HomeLobby data={menuList} supplierData={supplierData} />
    } else {
      return <HomeOtherLobby menuList={currentMenu as RaHallGameTypeProps} gamesId={v} supplierData={supplierData} />
    }
  }

  /** 首页 tabs 切换 */
  const onTabsChange = v => {
    setTabs(v)
    const currentData = menuList?.find(item => item?.id === v)
    currentData && setCurrentMenu(currentData)
    const tabId = v ? `?tabId=${v}` : ''
    history?.pushState({}, '', `${pageContext?.urlParsed?.pathname}${tabId}`)
    fadeInRef?.current?.fadeIn()
  }

  /** 获取游戏提供商 */
  const getGameGroupSupplierData = async () => {
    const { data, isOk } = await getGameGroupSupplier({})
    if (data && isOk) {
      setSupplierData(data)
    }
  }

  useMount(() => {
    getGameGroupSupplierData()
    const { tabId } = getRouterId()
    setTabs(Number(tabId || HomeDefaultTypeProps.none))
    fadeInRef?.current?.fadeIn()
  })

  useEffect(() => {
    const { tabId } = getRouterId()
    const currentData = menuList?.find(item => item?.id === Number(tabId))
    currentData && setCurrentMenu(currentData)

    if (gameTabsRef.current) {
      const newWidth = gameTabsRef.current.offsetWidth
      setGameTabsWidth(newWidth)
    }
  }, [menuList])

  return (
    <div>
      <section
        ref={homeRef}
        className={classNames(
          styles['recreation-home-wrap'],
          `${theme === ThemeEnum.dark ? 'dark-spin' : 'light-spin'}`
        )}
      >
        <HomePageBanner />

        <GamesSearch leftWidth={0} />
        <div className="game-middle-warp">
          <GameTabs
            tabs={menuList}
            currentTab={tabs}
            className="game-tabs-wrap"
            isShowTabContent={false}
            onChange={onTabsChange}
            forwardedRef={gameTabsRef}
          />
          {/* <GamesSearch leftWidth={gameTabsWidth} /> */}
        </div>
        <Spin className={loading ? 'loading-spin-box' : ''} loading={loading}>
          <>
            {handleHomeShow(tabs)}
            <PlaceFadeInMask ref={fadeInRef} duration={500} />
          </>
        </Spin>
        <div className="recreation-home-footer">
          <RankingListTable className="table-wrap" />
        </div>
        <AdvertPopup />

        {localeInfo?.customerService?.visible && <FloatingIconButton />}
      </section>
    </div>
  )
}

import { t } from '@lingui/macro'
import { useRequest } from 'ahooks'
import { link } from '@/helper/link'
import { useEffect, useRef } from 'react'
import { getGameTypeData } from '@/apis/game'
import { getGameCategoriesRoutePath, getGamesRoutePath, getGameSuppliersRoutePath } from '@/helper/route/game'
import { GameGroupSupplierTypeProps, RaHallGameTypeProps } from '@/typings/api/game'
import AllGames, { PaginationTypeEnum, TitleTypeEnum } from '@/features/recreation/component/all-games'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import { GetUseGameSuppliersMethods, RequestCacheTimeEnum } from '@/constants/common'
import { getGameTabCacheKey, shouldHiddenMoreByTotal } from '@/helper/game/home'
import { useGameStore } from '@/store/game'
import { RaGameTypeEnum } from '@/constants/game'
import RecommendGames from '@/features/recreation/theme/components/recommend-games'

type HomeLobbyProps = {
  gamesId: number
  menuList: RaHallGameTypeProps
  supplierData: GameGroupSupplierTypeProps[]
}

type PageListType = {
  pageNum: number
  pageSize: number
}

enum HomeOtherLobbyEnum {
  none,
  one = 1,
  maxNum = 21,
}

export default function HomeOtherLobby({ menuList, gamesId, supplierData }: HomeLobbyProps) {
  const { theme, localeInfo } = useCommonStore()
  const pageList = useRef<PageListType>({
    pageNum: HomeOtherLobbyEnum.one,
    pageSize: HomeOtherLobbyEnum.maxNum,
  })
  const { setPageCache, getPageCache } = useGameStore()

  const getHomeOtherLobby = async () => {
    const params = {
      typeCd: gamesId,
      pageNum: pageList?.current?.pageNum,
      pageSize: pageList?.current?.pageSize,
    }
    const { data, isOk } = await getGameTypeData(params)
    if (isOk && data) {
      return { total: data?.total ?? 0, list: data?.list ?? [] }
    }
    return { total: 0, list: [] }
  }

  const onEnter = v => {
    if (v?.isGroup) {
      const groupId = v?.groupId as number
      return link(getGamesRoutePath(groupId))
    }
    const ids = v.id as number
    const groupId = v?.groupId as number
    return link(getGamesRoutePath(ids, groupId))
  }

  const onLoadAllChange = () => {
    const targetUrl = getGameCategoriesRoutePath({ id: gamesId, name: menuList?.name, code: menuList?.code })
    link(targetUrl)
  }

  const { loading, data, mutate } = useRequest(getHomeOtherLobby, {
    cacheKey: getGameTabCacheKey(gamesId),
    cacheTime: RequestCacheTimeEnum.homeGameList,
    refreshDeps: [gamesId],
    onSuccess: () => {
      setPageCache(getGameTabCacheKey(gamesId), performance.now())
    },
  })
  const total = data?.total || 0
  const list = data?.list || []

  useEffect(() => {
    if (!getPageCache(getGameTabCacheKey(gamesId))) {
      mutate({ total: 0, list: [] })
    }
  }, [gamesId])

  return (
    <>
      <AllGames
        isImage
        total={total}
        data={list}
        showSearch={false}
        title={menuList?.name}
        titleIcon={theme === ThemeEnum.dark ? menuList?.icon : menuList?.whiteIcon}
        isLoading={loading}
        onEnterChange={onEnter}
        onLoadAll={onLoadAllChange}
        titleType={TitleTypeEnum.category}
        footerType={PaginationTypeEnum.all}
        showFooter={total >= HomeOtherLobbyEnum.maxNum}
        isShowButton={total > list.length}
      />
      {GetUseGameSuppliersMethods(localeInfo?.home?.hall?.provider) && (
        <RecommendGames
          title={t`features_layout_content_compnent_layout_menu_index_00f9dlrqie`}
          titleIcon="icon_games_bjl_sidebar_v2"
          className="game-wrap"
          gamesData={supplierData}
          type={RaGameTypeEnum.provider}
          onMore={() => link(getGameSuppliersRoutePath())}
          hiddenShowMore
          showMoreCard={!shouldHiddenMoreByTotal(supplierData?.length || 0)}
        />
      )}
    </>
  )
}

import HomePageBanner2z from '@/features/recreation/theme/2z/home-page-banner'
import HomePageBannerOk from '@/features/recreation/theme/ok/home-page-banner'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'

export default function HomePageBanner() {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <HomePageBanner2z />
    case InitThemeColor.ok:
      return <HomePageBannerOk />
    default:
      return <HomePageBanner2z />
  }
}

import { useInViewport, useRequest } from 'ahooks'
import { link } from '@/helper/link'
import { useRef, useEffect } from 'react'
import { getGameTypeData } from '@/apis/game'
import dayjs from 'dayjs'
import { getGamesRoutePath } from '@/helper/route/game'
import { RequestCacheTimeEnum } from '@/constants/common'
import { shouldHiddenMoreByTotal } from '@/helper/game/home'
import RecommendGames from '@/features/recreation/theme/components/recommend-games'

type HomeLobbyGameType = {
  id: number
  code: string
  title: string
  titleIcon: string
}

enum HomeLobbyGameEnum {
  one = 1,
  maxNum = 21,
}

export default function HomeLobbyGame(props: HomeLobbyGameType) {
  const lobbyDom = useRef<HTMLDivElement>(null)
  const [inViewport] = useInViewport(lobbyDom)
  const { id, title, titleIcon, code } = props

  const onMoreChange = () => {
    link(`/recreation/game-shows?typeCd=${id}&title=${title}&code=${code}`)
  }

  const getHomeData = async () => {
    const params = {
      typeCd: id,
      timestamp: dayjs().valueOf(),
      pageNum: HomeLobbyGameEnum.one,
      pageSize: HomeLobbyGameEnum.maxNum,
    }
    const { data, isOk } = await getGameTypeData(params)
    if (isOk && data) {
      return data
    }
  }

  const onEnter = v => {
    if (v?.isGroup) {
      const groupId = v?.groupId as number
      return link(getGamesRoutePath(groupId))
    }
    const ids = v.id as number
    const groupId = v?.groupId as number
    return link(getGamesRoutePath(ids, groupId))
  }

  const { run, loading, data } = useRequest(getHomeData, {
    manual: true,
    cacheKey: `menu-${id}`,
    cacheTime: RequestCacheTimeEnum.homeGameList,
  })

  /** 展示后加载数据 */
  useEffect(() => {
    if (inViewport && !data?.list?.length) {
      run()
    }
  }, [inViewport])

  return (
    <div ref={lobbyDom} className="game-box-content">
      <RecommendGames
        title={title}
        isImage
        onMore={onMoreChange}
        titleIcon={titleIcon}
        gamesData={data?.list || []}
        className="game-wrap"
        loading={loading}
        onEnterChange={onEnter}
        hiddenShowMore
        showMoreCard={!shouldHiddenMoreByTotal(data?.total || 0)}
      />
    </div>
  )
}
